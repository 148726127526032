<template>
  <div id="page-tower-details" class="px-md-5 py-md-4" v-if="tower">
    <div class="tower-details-cover">
      <img :src="tower.coverImage.url" />
      <div class="tower-details-name">
        <h1>{{ tower.name }}</h1>
        <h2>{{ tower.address }}</h2>
      </div>
      <div class="tower-details-map-container">
        <div id="tower-details-map" class="h-100 w-100"></div>
      </div>
      <h-scroller>
        <div class="tower-details-tabs">
          <a
            href="#"
            @click.prevent="goToSection('overview')"
            :class="{ active: $route.name == 'tower.details.overview' }"
          >
            Overview
          </a>
          <a
            href="#"
            @click.prevent="goToSection('assets')"
            :class="{ active: $route.name == 'tower.details.assets' }"
          >
            Assets
          </a>
          <a
            href="#"
            @click.prevent="goToSection('documents')"
            :class="{
              active: $route.name == 'tower.details.documents',
            }"
          >
            Documents
          </a>
          <a
            href="#"
            @click.prevent="goToSection('interactiveTour')"
            :class="{
              active: $route.name == 'tower.details.interactiveTour',
            }"
          >
            Interactive Tour
          </a>
          <a
            href="#"
            @click.prevent="goToSection('3dVisualisation')"
            :class="{
              active: $route.name == 'tower.details.3dVisualisation',
            }"
          >
            3D Visualisation
          </a>
          <a
            href="https://static.inference.asia/cesium/index.html"
            target="_blank"
            :class="{
              active: $route.name == 'tower.details.azimuth',
            }"
          >
            Azimuth
          </a>
          <!-- <a
            href="#"
            @click.prevent="goToSection('requestInstallation')"
            :class="{
              active: $route.name == 'tower.details.requestInstallation',
            }"
          >
            Request Installation
          </a> -->
        </div>
      </h-scroller>
    </div>
    <div class="tower-details-section py-3 px-5 px-md-0">
      <router-view v-if="tower" :tower="tower" />
    </div>
  </div>
</template>

<script>
import API from "@/api";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import "mapbox-gl/dist/mapbox-gl.css";
import HorizontalScroller from "@/components/HorizontalScroller";

export default {
  name: "towerDetails",
  components: {
    "h-scroller": HorizontalScroller,
  },
  data() {
    return {
      tower: null,
      map: null,
      showMap: false,
      isLoading: false,
    };
  },
  watch: {
    "$route.name": {
      handler() {
        if (this.$route.name === "tower.details") {
          this.goToSection("overview");
        }
      },
      immediate: true,
    },
    isLoading: {
      handler() {
        this.$parent.isLoading = this.isLoading;
      },
    },
  },
  methods: {
    initMap() {
      return new Promise((resolve, reject) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        this.map = new mapboxgl.Map({
          container: "tower-details-map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: [this.tower.lng, this.tower.lat],
          zoom: 12,
          interactive: false,
        });

        this.map.on("load", () => {
          new mapboxgl.Marker()
            .setLngLat([this.tower.lng, this.tower.lat])
            .addTo(this.map);

          this.showMap = true;
          resolve();
        });
      });
    },
    goToSection(section) {
      if (`tower.details.${section}` !== this.$route.name) {
        this.$router.replace({
          name: `tower.details.${section}`,
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
    async getTower() {
      this.isLoading = true;
      API.get(`sites/${this.$route.params.id}`)
        .then((retVal) => {
          this.tower = retVal.data;
          document.title = `${this.tower.name} | magardTELE`;

          setTimeout(() => {
            this.initMap();
            this.isLoading = false;
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getTower();
  },
};
</script>