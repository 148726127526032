var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tower)?_c('div',{staticClass:"px-md-5 py-md-4",attrs:{"id":"page-tower-details"}},[_c('div',{staticClass:"tower-details-cover"},[_c('img',{attrs:{"src":_vm.tower.coverImage.url}}),_c('div',{staticClass:"tower-details-name"},[_c('h1',[_vm._v(_vm._s(_vm.tower.name))]),_c('h2',[_vm._v(_vm._s(_vm.tower.address))])]),_vm._m(0),_c('h-scroller',[_c('div',{staticClass:"tower-details-tabs"},[_c('a',{class:{ active: _vm.$route.name == 'tower.details.overview' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToSection('overview')}}},[_vm._v(" Overview ")]),_c('a',{class:{ active: _vm.$route.name == 'tower.details.assets' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToSection('assets')}}},[_vm._v(" Assets ")]),_c('a',{class:{
            active: _vm.$route.name == 'tower.details.documents',
          },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToSection('documents')}}},[_vm._v(" Documents ")]),_c('a',{class:{
            active: _vm.$route.name == 'tower.details.interactiveTour',
          },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToSection('interactiveTour')}}},[_vm._v(" Interactive Tour ")]),_c('a',{class:{
            active: _vm.$route.name == 'tower.details.3dVisualisation',
          },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToSection('3dVisualisation')}}},[_vm._v(" 3D Visualisation ")]),_c('a',{class:{
            active: _vm.$route.name == 'tower.details.azimuth',
          },attrs:{"href":"https://static.inference.asia/cesium/index.html","target":"_blank"}},[_vm._v(" Azimuth ")])])])],1),_c('div',{staticClass:"tower-details-section py-3 px-5 px-md-0"},[(_vm.tower)?_c('router-view',{attrs:{"tower":_vm.tower}}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tower-details-map-container"},[_c('div',{staticClass:"h-100 w-100",attrs:{"id":"tower-details-map"}})])}]

export { render, staticRenderFns }